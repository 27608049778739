import { graphql } from "gatsby";
import React from "react";
import PortableText from '@/components/portableText'

import Layout from "@/components/layout";
import HeaderThree from "@/components/header-three";
import MenuContextProvider from "@/context/menu-context";
import Footer from "@/components/footer";

import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client-config'

export const query = graphql`
  query AttorneyTemplateQuery($id: String!) {
    attorney: sanityAttorney(id: { eq: $id }) {
      id,
      name,
      email,
      vcard {
        asset {
          url
        }
      },
      phone,
      title,
      undergrad,
      undergradDistinction,
      lawSchool,
      lawSchoolDistinction
      admissions,
      associations,
      _rawBio,
      bio {
        _key
        _type
        style
        list
        _rawChildren
      },
      linkedIn,
      image {
        hotspot{
          height
          width
          x
          y
        }
        crop {
            bottom
            left
            right
            top
        }
        asset {
          url
        }
      }
    }  
  }
`;


const AttorneyTemplate = (props) => {
  const { data } = props;
  const attorney = data && data.attorney;

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <MenuContextProvider>
    {attorney && (
      <Layout PageTitle={attorney.name || "Untitled"}>
        <HeaderThree />
        <div className="md:w-[53%] w-full mx-auto">
          <img src={urlFor(attorney.image).width(1600).height(914).url()} alt={attorney.name || "Untitled"} className="img-fluid attorneyImg"/>
        </div>
        <div className="p-4 col-lg-8 mx-auto">
            <div className="border-bottom pb-2 mb-4 flex justify-between">
              <div>
                <h3 className="lawName">{attorney.name || "Untitled"}</h3>
                <h5 className="lawTitle">{attorney.title || "Attorney"}</h5>
                {attorney.phone !== "null" && <p className="lawPhone"><a href={"tel:"+attorney.phone || "315-437-7600"} style={{fontWeight:"normal"}}>{attorney.phone || "315-437-7600"}</a></p>}
                {attorney.email !== "null" && <p className="lawEmail"><a href={"mailto:"+attorney.email}   style={{fontWeight:"normal"}}>{attorney.email}</a></p>}
            </div>
            <div className="flex gap-2 text-2xl">
                {attorney.vcard && attorney.vcard.asset && <a className="hover:text-ferraraTeal" href={attorney.vcard.asset.url} download={attorney.vcard.asset.url}><i className="far fa-address-card" aria-label="Virtual Card"></i></a>}
                {attorney.email &&<a className="hover:text-ferraraTeal" href={"mailto:"+attorney.email}><i className="fas fa-envelope" aria-label="Email"></i></a>}
                {attorney.linkedIn &&<a className="hover:text-ferraraTeal" href={attorney.linkedIn} rel="noreferrer" target="_blank"><i class="fab fa-linkedin" aria-label="LinkedIn"></i></a>}
            </div>
          </div>
          <div className="d-flex">
              <div className="col-8 lawPadding portable">
                <PortableText blocks={attorney._rawBio}/>
              </div>
              <div className="col-4 ml-4">
                {attorney.admissions.length > 0 &&
                  <h6 className="lawBold">Admissions</h6>}
                  {
                    attorney.admissions.map(admissions => (
                      <p key={admissions}>
                        {admissions}
                      </p>
                    ))
                  }

                {attorney.associations.length > 0  &&
                  <h6 className="lawBold mt-3">Associations</h6>}
                  {
                    attorney.associations.map(associations => (
                      <p key={associations}>
                        {associations}
                      </p>
                    ))
                  }
                
                 
                  {(attorney.undergrad || attorney.lawSchool) && <h6 className="lawBold mt-3">Education</h6>}
                  {attorney.undergrad &&<p>{attorney.undergrad}{attorney.undergradDistinction === "Magna Cum Laude" && <em>, magna cum laude</em>}{attorney.undergradDistinction === "Cum Laude" && <em>, cum Laude</em>}</p>}
                  {attorney.lawSchool &&<p>{attorney.lawSchool}{attorney.lawSchoolDistinction === "Magna Cum Laude" && <em>, magna cum laude</em>}{attorney.lawSchoolDistinction === "Cum Laude" && <em>, cum Laude</em>}</p>}
              </div>
          </div>
        </div>
        <Footer />
      </Layout>
      )}
    </MenuContextProvider>
  );
};

export default AttorneyTemplate;

